var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e8uSfLklqqkc_YXfeNqDH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

// const SENTRY_DSN_STRING =
//   process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.STAGE === "Production")
  Sentry.init({
    dsn: "https://cad685f151a14213a7490ac8015856e6@o1161108.ingest.sentry.io/4504672309018624",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: parseFloat(
      process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
    ),
    environment: process.env.STAGE,
    beforeSend(event, hint) {
      const error = hint?.originalException;

      // List of error messages or patterns to exclude
      const excludedErrors = [
        "ResizeObserver loop completed with undelivered notifications",
        "Window.getComputedStyle: Argument 1 is not an object",
        "Loading CSS chunk 450 failed",
        "Cannot read properties of null (reading 'version')",
        "Cannot read properties of null (reading 'appendChild')",
        "Cannot read properties of undefined (reading 'DomainData')",
        "undefined is not an object (evaluating 't.Domain')",
        "this.selector is null",
        "Cannot read properties of undefined (reading 'html')",
        "Cannot read properties of undefined (reading 'Domain')",
        "r is undefined",
      ];

      // Check if the error message matches any excluded pattern
      if (error instanceof Error) {
        const errorMessage =
          error?.message || event?.exception?.values?.[0]?.value || "";
        if (
          errorMessage &&
          excludedErrors?.some((pattern) => errorMessage?.includes(pattern))
        ) {
          return null; // Block the event from being sent to Sentry
        }

        // Additional check for specific stack trace origins (optional for precision)
        const stackFrames =
          event?.exception?.values?.[0]?.stacktrace?.frames || [];
        const isFromExcludedScript = stackFrames?.some((frame) =>
          frame?.filename?.match(
            /otBannerSdk\.js|clarity\.js|feefo-widget\.js|otSDKStub\.js/,
          ),
        );

        if (
          isFromExcludedScript &&
          errorMessage?.includes("Cannot read properties")
        ) {
          return null; // Block generic "Cannot read properties" errors from these scripts
        }
      }

      return event; // Send all other events to Sentry
    },
  });
